/**
* ProjectUserRole_TransitionsEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectUserRoleEntity } from "./ProjectUserRoleEntity";
import { BugTransitionEntity } from "./BugTransitionEntity";


declare global {
    interface EntityTypeMap {
        "ProjectUserRole_Transitions": ProjectUserRole_TransitionsEntity
    }
}

export abstract class ProjectUserRole_TransitionsEntity extends BaseEntity {

	 abstract relationId: string;
	 abstract role: ProjectUserRoleEntity;
	 abstract transition: BugTransitionEntity;
	 abstract templateId?: string;
	
}
