/**
* RegionMemberEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { RegionEntity } from "./RegionEntity";
import { PersonEntity } from "./PersonEntity";


declare global {
    interface EntityTypeMap {
        "RegionMember": RegionMemberEntity
    }
}

export abstract class RegionMemberEntity extends BaseEntity {

	 abstract regionMemberId: string;
	 abstract type: string;
	 abstract region: RegionEntity;
	 abstract templateId?: string;
	 abstract person?: PersonEntity;
	
}
