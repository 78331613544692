/**
* RemarkCategoryHierarchyEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { RemarkCategoryEntity } from "./RemarkCategoryEntity";
import { ProjectEntity } from "./ProjectEntity";
import { RemarkEntity } from "./RemarkEntity";


declare global {
    interface EntityTypeMap {
        "RemarkCategoryHierarchy": RemarkCategoryHierarchyEntity
    }
}

export abstract class RemarkCategoryHierarchyEntity extends BaseEntity {

	 abstract remarkCategoryHierarchyId: string;
	 abstract userOrder: number;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract category?: RemarkCategoryEntity;
	 abstract parentHierarchy?: RemarkCategoryHierarchyEntity;
	 abstract children?: ReadonlyArray<RemarkCategoryHierarchyEntity>;
	 abstract remarks?: ReadonlyArray<RemarkEntity>;
	
}
