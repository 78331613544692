/**
* PhaseEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { VisitTypeEntity } from "./VisitTypeEntity";
import { Phase_BugGroupsEntity } from "./Phase_BugGroupsEntity";
import { VisitEntity } from "./VisitEntity";
import { BugEntity } from "./BugEntity";
import { BugNoteEntity } from "./BugNoteEntity";


declare global {
    interface EntityTypeMap {
        "Phase": PhaseEntity
    }
}

export abstract class PhaseEntity extends BaseEntity {

	 abstract phaseId: string;
	 abstract nameComputed: string;
	 abstract project: ProjectEntity;
	 abstract bugGroupsRelations: ReadonlyArray<Phase_BugGroupsEntity>;
	 abstract templateId?: string;
	 abstract index?: number;
	 abstract name?: string;
	 abstract externalId?: string;
	 abstract nameSystem?: string;
	 abstract visitTypes?: ReadonlyArray<VisitTypeEntity>;
	 abstract visits?: ReadonlyArray<VisitEntity>;
	 abstract bugs?: ReadonlyArray<BugEntity>;
	 abstract bugNotes?: ReadonlyArray<BugNoteEntity>;
	
}
