/**
* BugEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { VisitEntity } from "./VisitEntity";
import { MapEntity } from "./MapEntity";
import { WorkPackageEntity } from "./WorkPackageEntity";
import { MapLocationEntity } from "./MapLocationEntity";
import { PhaseEntity } from "./PhaseEntity";
import { BugGroupEntity } from "./BugGroupEntity";
import { FormEntity } from "./FormEntity";
import { FormTypeVersionEntity } from "./FormTypeVersionEntity";
import { BugNoteEntity } from "./BugNoteEntity";
import { BugAnnotationEntity } from "./BugAnnotationEntity";


declare global {
    interface EntityTypeMap {
        "Bug": BugEntity
    }
}

export abstract class BugEntity extends BaseEntity {

	 abstract bugId: string;
	 abstract index: number;
	 abstract creationDate: Date;
	 abstract temp: boolean;
	 abstract visit: VisitEntity;
	 abstract map: MapEntity;
	 abstract templateId?: string;
	 abstract dueDate?: Date;
	 abstract location?: string;
	 abstract positionX?: number;
	 abstract positionY?: number;
	 abstract text?: string;
	 abstract relatedFormFieldId?: string;
	 abstract noPosition?: boolean;
	 abstract closeVisit?: VisitEntity;
	 abstract workPackage?: WorkPackageEntity;
	 abstract mapLocation?: MapLocationEntity;
	 abstract phase?: PhaseEntity;
	 abstract bugGroup?: BugGroupEntity;
	 abstract relatedParentBug?: BugEntity;
	 abstract relatedForm?: FormEntity;
	 abstract relatedFormHistory?: FormTypeVersionEntity;
	 abstract currentPhase?: PhaseEntity;
	 abstract stateNoteCache?: BugNoteEntity;
	 abstract relatedChildBugs?: ReadonlyArray<BugEntity>;
	 abstract notes?: ReadonlyArray<BugNoteEntity>;
	 abstract annotations?: ReadonlyArray<BugAnnotationEntity>;
	
}
