/**
* MapLocationEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { MapEntity } from "./MapEntity";
import { BugEntity } from "./BugEntity";


declare global {
    interface EntityTypeMap {
        "MapLocation": MapLocationEntity
    }
}

export abstract class MapLocationEntity extends BaseEntity {

	 abstract mapLocationId: string;
	 abstract bimGuid: string;
	 abstract type: string;
	 abstract map: MapEntity;
	 abstract bugs: ReadonlyArray<BugEntity>;
	 abstract templateId?: string;
	 abstract name?: string;
	
}
