/**
* RemarkCategoryEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { PersonEntity } from "./PersonEntity";
import { ProjectMemberEntity } from "./ProjectMemberEntity";
import { WorkPackageEntity } from "./WorkPackageEntity";
import { RemarkCategoryHierarchyEntity } from "./RemarkCategoryHierarchyEntity";


declare global {
    interface EntityTypeMap {
        "RemarkCategory": RemarkCategoryEntity
    }
}

export abstract class RemarkCategoryEntity extends BaseEntity {

	 abstract remarkCategoryId: string;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract text?: string;
	 abstract tag?: number;
	 abstract displayName?: string;
	 abstract visitor?: PersonEntity;
	 abstract projectMember?: ProjectMemberEntity;
	 abstract workPackage?: WorkPackageEntity;
	 abstract contact?: PersonEntity;
	 abstract hierarchy?: ReadonlyArray<RemarkCategoryHierarchyEntity>;
	
}
