/**
* PlanningEventLogEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectUserEntity } from "./ProjectUserEntity";
import { PlanningEventEntity } from "./PlanningEventEntity";


declare global {
    interface EntityTypeMap {
        "PlanningEventLog": PlanningEventLogEntity
    }
}

export abstract class PlanningEventLogEntity extends BaseEntity {

	 abstract planningEventLogId: string;
	 abstract progress: number;
	 abstract creationDate: Date;
	 abstract planningEvent: PlanningEventEntity;
	 abstract user?: ProjectUserEntity;
	
}
