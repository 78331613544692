/**
* PlanningEventCategoryEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { PlanningEventEntity } from "./PlanningEventEntity";


declare global {
    interface EntityTypeMap {
        "PlanningEventCategory": PlanningEventCategoryEntity
    }
}

export abstract class PlanningEventCategoryEntity extends BaseEntity {

	 abstract planningEventCategoryId: string;
	 abstract text: string;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract planningEvents?: ReadonlyArray<PlanningEventEntity>;
	
}
