/**
* MapVersionEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { MapEntity } from "./MapEntity";


declare global {
    interface EntityTypeMap {
        "MapVersion": MapVersionEntity
    }
}

export abstract class MapVersionEntity extends BaseEntity {

	 abstract mapVersionId: string;
	 abstract filename: string;
	 abstract index: number;
	 abstract cropBoxOriginX: number;
	 abstract cropBoxOriginY: number;
	 abstract cropBoxWidth: number;
	 abstract cropBoxHeight: number;
	 abstract mediaBoxOriginX: number;
	 abstract mediaBoxOriginY: number;
	 abstract mediaBoxWidth: number;
	 abstract mediaBoxHeight: number;
	 abstract rotation: number;
	 abstract map: MapEntity;
	 abstract date?: Date;
	 abstract templateId?: string;
	 abstract activeForMap?: MapEntity;
	
}
