/**
* FormTypeVersionEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { FormTypeEntity } from "./FormTypeEntity";
import { FormTypeViewEntity } from "./FormTypeViewEntity";
import { FormEntity } from "./FormEntity";
import { BugEntity } from "./BugEntity";


declare global {
    interface EntityTypeMap {
        "FormTypeVersion": FormTypeVersionEntity
    }
}

export abstract class FormTypeVersionEntity extends BaseEntity {

	 abstract formTypeVersionId: string;
	 abstract date: Date;
	 abstract formType: FormTypeEntity;
	 abstract views: ReadonlyArray<FormTypeViewEntity>;
	 abstract forms: ReadonlyArray<FormEntity>;
	 abstract bugs: ReadonlyArray<BugEntity>;
	 abstract templateId?: string;
	 abstract activeForType?: FormTypeEntity;
	
}
