/**
* BugAnnotationEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { BugEntity } from "./BugEntity";


declare global {
    interface EntityTypeMap {
        "BugAnnotation": BugAnnotationEntity
    }
}

export abstract class BugAnnotationEntity extends BaseEntity {

	 abstract creationDate: Date;
	 abstract hasMetadata: boolean;
	 abstract drawingHint: number;
	 abstract temp: boolean;
	 abstract bugAnnotationId: string;
	 abstract bug: BugEntity;
	 abstract parentAnnotatedEntity: BugEntity;
	 abstract templateId?: string;
	
}
