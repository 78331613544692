/**
* WorkPackageEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { PersonEntity } from "./PersonEntity";
import { WorkPackageDescriptionEntity } from "./WorkPackageDescriptionEntity";
import { PlanningEventEntity } from "./PlanningEventEntity";
import { BugEntity } from "./BugEntity";
import { RemarkCategoryEntity } from "./RemarkCategoryEntity";
import { DocumentEntity } from "./DocumentEntity";


declare global {
    interface EntityTypeMap {
        "WorkPackage": WorkPackageEntity
    }
}

export abstract class WorkPackageEntity extends BaseEntity {

	 abstract workPackageId: string;
	 abstract index: string;
	 abstract name: string;
	 abstract color: number;
	 abstract project: ProjectEntity;
	 abstract planningEvents: ReadonlyArray<PlanningEventEntity>;
	 abstract bugs: ReadonlyArray<BugEntity>;
	 abstract templateId?: string;
	 abstract contact?: PersonEntity;
	 abstract descriptions?: ReadonlyArray<WorkPackageDescriptionEntity>;
	 abstract remarkCategory?: RemarkCategoryEntity;
	 abstract documents?: ReadonlyArray<DocumentEntity>;
	
}
