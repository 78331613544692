import "reflect-metadata";

import { inject, injectable } from "inversify";
import { type UserManager } from "oidc-client-ts";

import { OAuth_Client_UserManager_DIID } from "./oauth-client.type";

@injectable()
export class OAuthClientService {
    constructor(
        @inject(OAuth_Client_UserManager_DIID.symbol)
        protected readonly userManager: UserManager,
    ) {}

    /**
     * Start a sign in flow with the prompt set to "registration" to force the
     * registration of a new user.
     */
    public async startRegistrationFlow(): Promise<void | never> {
        await this.userManager.signinRedirect({
            prompt: "registration",
        });
    }

    /**
     * If is required, redirects to the OAuth provider login page
     */
    public async startLoginFlow(): Promise<void> {
        await this.userManager.signinRedirect({
            url_state: window.location.hash ? encodeURIComponent(window.location.hash) : undefined,
        });
    }

    /**
     * @returns The urlState (location.hash) to redirect to after the login flow is completed
     */
    public async completeLoginFlow(): Promise<string | undefined> {
        const userFromRedirect = await this.userManager.signinCallback();
        if (!userFromRedirect) {
            return undefined;
        }
        const urlState = userFromRedirect.url_state ? decodeURIComponent(userFromRedirect.url_state) : undefined;

        return urlState;
    }

    /**
     * Try to silently authenticate the user if possible, otherwise throw an error.
     */
    public async autoLoginFlow(): Promise<void> {
        const canBeSilentlyAuthenticated = await this.canBeSilentlyAuthenticated();
        if (!canBeSilentlyAuthenticated) {
            throw new Error("User cannot be silently authenticated");
        }

        try {
            await this.userManager.signinSilent();
        } catch (e) {
            await this.logout();
            throw e;
        }
    }

    public async getAccessToken(): Promise<string | null> {
        const user = await this.userManager.getUser();
        if (!user) {
            return null;
        }
        return user.access_token;
    }

    public async logout(): Promise<void> {
        try {
            await this.userManager.signoutSilent({
                state: "logout", // forcing a state for logout handshake
                post_logout_redirect_uri: window.location.origin + "/#/logout",
            });
        } catch (e) {
            // Removing user if it wasn't already done in signoutSilent.
            await this.userManager.removeUser();
            throw e;
        }
    }

    /**
     * Returns true if the user can be / is silently authenticated
     */
    public async canBeSilentlyAuthenticated(): Promise<boolean> {
        const user = await this.userManager.getUser();
        if (!user) {
            return false;
        }
        if (user.expired) {
            return false;
        }
        if (!user.access_token) {
            return false;
        }
        if (!user.refresh_token) {
            return false;
        }
        return true;
    }

    /**
     * Adapt the error description for the UI
     */
    public formatOAuthErrorDescription(errorDescription: string): string {
        // Replace All + by space
        return errorDescription.replace(/\+/g, " ");
    }
}
