/**
* RemarkEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { VisitEntity } from "./VisitEntity";
import { RemarkCategoryHierarchyEntity } from "./RemarkCategoryHierarchyEntity";
import { RemarkStyleEntity } from "./RemarkStyleEntity";
import { PersonEntity } from "./PersonEntity";
import { RemarkAnnotationEntity } from "./RemarkAnnotationEntity";


declare global {
    interface EntityTypeMap {
        "Remark": RemarkEntity
    }
}

export abstract class RemarkEntity extends BaseEntity {

	 abstract remarkId: string;
	 abstract hasTaskData: boolean;
	 abstract visit: VisitEntity;
	 abstract templateId?: string;
	 abstract dueDate?: string;
	 abstract status?: string;
	 abstract text?: string;
	 abstract time?: Date;
	 abstract startDate?: Date;
	 abstract endDate?: Date;
	 abstract progress?: number;
	 abstract closeVisit?: VisitEntity;
	 abstract parentRemark?: RemarkEntity;
	 abstract categoryHierarchy?: RemarkCategoryHierarchyEntity;
	 abstract style?: RemarkStyleEntity;
	 abstract contact?: PersonEntity;
	 abstract childrenRemarks?: ReadonlyArray<RemarkEntity>;
	 abstract annotations?: ReadonlyArray<RemarkAnnotationEntity>;
	
}
