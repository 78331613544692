/**
* BugTransitionEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { BugStateEntity } from "./BugStateEntity";
import { BugWorkflowEntity } from "./BugWorkflowEntity";
import { ProjectUserRole_TransitionsEntity } from "./ProjectUserRole_TransitionsEntity";
import { BugNoteEntity } from "./BugNoteEntity";


declare global {
    interface EntityTypeMap {
        "BugTransition": BugTransitionEntity
    }
}

export abstract class BugTransitionEntity extends BaseEntity {

	 abstract bugTransitionId: string;
	 abstract equivBugTransition: number;
	 abstract name: string;
	 abstract nameDone: string;
	 abstract priority: number;
	 abstract autoActivation: number;
	 abstract endState: BugStateEntity;
	 abstract bugWorkflow: BugWorkflowEntity;
	 abstract rolesRelations: ReadonlyArray<ProjectUserRole_TransitionsEntity>;
	 abstract templateId?: string;
	 abstract startState?: BugStateEntity;
	 abstract bugNotes?: ReadonlyArray<BugNoteEntity>;
	
}
