/**
* RemarkAnnotationEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { RemarkEntity } from "./RemarkEntity";


declare global {
    interface EntityTypeMap {
        "RemarkAnnotation": RemarkAnnotationEntity
    }
}

export abstract class RemarkAnnotationEntity extends BaseEntity {

	 abstract creationDate: Date;
	 abstract hasMetadata: boolean;
	 abstract drawingHint: number;
	 abstract temp: boolean;
	 abstract remarkAnnotationId: string;
	 abstract remark: RemarkEntity;
	 abstract parentAnnotatedEntity: RemarkEntity;
	 abstract templateId?: string;
	
}
