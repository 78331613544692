/**
* ProjectUserEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { ProjectUserRoleEntity } from "./ProjectUserRoleEntity";
import { ProjectUserRightEntity } from "./ProjectUserRightEntity";
import { VisitEntity } from "./VisitEntity";
import { PlanningEventLogEntity } from "./PlanningEventLogEntity";
import { BugNoteEntity } from "./BugNoteEntity";
import { ReportEntity } from "./ReportEntity";


declare global {
    interface EntityTypeMap {
        "ProjectUser": ProjectUserEntity
    }
}

export abstract class ProjectUserEntity extends BaseEntity {

	 abstract projectUserId: string;
	 abstract cloudUserId: string;
	 abstract email: string;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract name?: string;
	 abstract company?: string;
	 abstract role?: ProjectUserRoleEntity;
	 abstract right?: ProjectUserRightEntity;
	 abstract ownerOfProject?: ProjectEntity;
	 abstract projectPlanningMaster?: ProjectEntity;
	 abstract visits?: ReadonlyArray<VisitEntity>;
	 abstract planningEventLogs?: ReadonlyArray<PlanningEventLogEntity>;
	 abstract bugNotes?: ReadonlyArray<BugNoteEntity>;
	 abstract reports?: ReadonlyArray<ReportEntity>;
	
}
