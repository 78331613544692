/**
* DeliveryVisitEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { VisitEntity } from "./VisitEntity";


declare global {
    interface EntityTypeMap {
        "DeliveryVisit": DeliveryVisitEntity
    }
}

export abstract class DeliveryVisitEntity extends BaseEntity {

	 abstract deliveryVisitId: string;
	 abstract visit: VisitEntity;
	 abstract templateId?: string;
	 abstract keysDelivered?: number;
	 abstract keysTotal?: number;
	 abstract gasMeter?: number;
	 abstract electricMeter?: number;
	 abstract waterMeter?: number;
	
}
