/**
* DocumentVersionEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { DocumentEntity } from "./DocumentEntity";


declare global {
    interface EntityTypeMap {
        "DocumentVersion": DocumentVersionEntity
    }
}

export abstract class DocumentVersionEntity extends BaseEntity {

	 abstract documentVersionId: string;
	 abstract downloadPolicy: string;
	 abstract index: string;
	 abstract size: string;
	 abstract date: Date;
	 abstract filename: string;
	 abstract document: DocumentEntity;
	 abstract templateId?: string;
	 abstract mimeType?: string;
	 abstract activeForDocument?: DocumentEntity;
	
}
