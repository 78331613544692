/**
* ProjectTemplateVersionEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";


declare global {
    interface EntityTypeMap {
        "ProjectTemplateVersion": ProjectTemplateVersionEntity
    }
}

export abstract class ProjectTemplateVersionEntity extends BaseEntity {

	 abstract projectTemplateVersionId: string;
	 abstract projectTemplateId: string;
	 abstract version: string;
	 abstract project: ProjectEntity;
	
}
