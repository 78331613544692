/**
* FormEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { FormTypeVersionEntity } from "./FormTypeVersionEntity";
import { VisitEntity } from "./VisitEntity";
import { BugEntity } from "./BugEntity";


declare global {
    interface EntityTypeMap {
        "Form": FormEntity
    }
}

export abstract class FormEntity extends BaseEntity {

	 abstract formId: string;
	 abstract data: string;
	 abstract formTypeVersion: FormTypeVersionEntity;
	 abstract visit: VisitEntity;
	 abstract bugs: ReadonlyArray<BugEntity>;
	 abstract templateId?: string;
	 abstract creator?: string;
	 abstract creatorId?: number;
	 abstract indexTypeUser?: number;
	 abstract filename?: string;
	 abstract object?: string;
	
}
