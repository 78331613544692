/**
* WorkPackageDescriptionEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { WorkPackageEntity } from "./WorkPackageEntity";


declare global {
    interface EntityTypeMap {
        "WorkPackageDescription": WorkPackageDescriptionEntity
    }
}

export abstract class WorkPackageDescriptionEntity extends BaseEntity {

	 abstract workPackageDescriptionId: string;
	 abstract workPackage: WorkPackageEntity;
	 abstract templateId?: string;
	 abstract text?: string;
	
}
