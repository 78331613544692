/**
* BugStateEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { BugWorkflowEntity } from "./BugWorkflowEntity";
import { BugGroupEntity } from "./BugGroupEntity";
import { BugTransitionEntity } from "./BugTransitionEntity";
import { VisitTypeEntity } from "./VisitTypeEntity";
import { BugNoteEntity } from "./BugNoteEntity";


declare global {
    interface EntityTypeMap {
        "BugState": BugStateEntity
    }
}

export abstract class BugStateEntity extends BaseEntity {

	 abstract bugStateId: string;
	 abstract equivBugState: number;
	 abstract name: string;
	 abstract orderHint: number;
	 abstract enterAction: number;
	 abstract bugWorkflow: BugWorkflowEntity;
	 abstract templateName?: string;
	 abstract templateId?: string;
	 abstract color?: number;
	 abstract bugGroup?: BugGroupEntity;
	 abstract bugTransitionStarts?: ReadonlyArray<BugTransitionEntity>;
	 abstract bugTransitionEnds?: ReadonlyArray<BugTransitionEntity>;
	 abstract visitTypes?: ReadonlyArray<VisitTypeEntity>;
	 abstract bugNotes?: ReadonlyArray<BugNoteEntity>;
	
}
