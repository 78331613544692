/**
* ProjectUserRole_BugGroupsEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectUserRoleEntity } from "./ProjectUserRoleEntity";
import { BugGroupEntity } from "./BugGroupEntity";


declare global {
    interface EntityTypeMap {
        "ProjectUserRole_BugGroups": ProjectUserRole_BugGroupsEntity
    }
}

export abstract class ProjectUserRole_BugGroupsEntity extends BaseEntity {

	 abstract relationId: string;
	 abstract type: number;
	 abstract role: ProjectUserRoleEntity;
	 abstract templateId?: string;
	 abstract bugGroup?: BugGroupEntity;
	
}
