/**
* promoimmoEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { VisitEntity } from "./VisitEntity";


declare global {
    interface EntityTypeMap {
        "promoimmo": promoimmoEntity
    }
}

export abstract class promoimmoEntity extends BaseEntity {

	 abstract promoimmoId: string;
	 abstract visit: VisitEntity;
	 abstract promoimmo_moa_represente?: string;
	 abstract promoimmo_moa_qualite?: string;
	 abstract promoimmo_represente?: string;
	 abstract promoimmo_societe?: string;
	 abstract promoimmo_qualite?: string;
	 abstract promoimmo_nbr_jours_lever?: number;
	 abstract promoimmo_declare?: string;
	 abstract promoimmo_motif_non_reception?: string;
	 abstract promoimmo_num_batiment?: string;
	 abstract promoimmo_num_logement?: string;
	 abstract promoimmo_num_parking?: string;
	 abstract promoimmo_num_cave?: string;
	 abstract promoimmo_description_travaux?: string;
	 abstract promoimmo_vigiks?: number;
	 abstract promoimmo_cle_paliere?: number;
	 abstract promoimmo_carte_paliere?: number;
	 abstract promoimmo_cle_garage?: number;
	 abstract promoimmo_cle_caves?: number;
	 abstract promoimmo_bal?: number;
	 abstract promoimmo_telecommande_vr?: number;
	 abstract promoimmo_acces_parking?: number;
	 abstract promoimmo_emetteur_ssol?: number;
	 abstract promoimmo_emetteur_portail?: number;
	 abstract promoimmo_notes_clefs?: string;
	 abstract promoimmo_donne_quitus?: boolean;
	 abstract promoimmo_paiment_regle?: boolean;
	 abstract promoimmo_cheque_recu?: boolean;
	 abstract promoimmo_cheque_montant?: string;
	 abstract promoimmo_compteur_eau_froid?: string;
	 abstract promoimmo_compteur_eau_chaud?: string;
	 abstract promoimmo_compteur_chauffage?: string;
	 abstract promoimmo_compteur_gaz?: string;
	 abstract promoimmo_compteur_electricite?: string;
	 abstract promoimmo_dpe?: string;
	 abstract promoimmo_certification?: string;
	 abstract promoimmo_chaudiere?: string;
	 abstract promoimmo_thermostat?: string;
	 abstract promoimmo_radiateur?: string;
	 abstract promoimmo_videophone?: string;
	 abstract promoimmo_kitchenette?: string;
	 abstract promoimmo_comptage?: string;
	 abstract promoimmo_emetteur_garage?: string;
	 abstract promoimmo_telecommande_bso?: string;
	 abstract promoimmo_coffret_comm?: string;
	 abstract promoimmo_code_acces?: string;
	 abstract promoimmo_legal_country?: string;
	
}
