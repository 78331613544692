/**
* Phase_BugGroupsEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { PhaseEntity } from "./PhaseEntity";
import { BugGroupEntity } from "./BugGroupEntity";


declare global {
    interface EntityTypeMap {
        "Phase_BugGroups": Phase_BugGroupsEntity
    }
}

export abstract class Phase_BugGroupsEntity extends BaseEntity {

	 abstract relationId: string;
	 abstract phase: PhaseEntity;
	 abstract templateId?: string;
	 abstract bugGroup?: BugGroupEntity;
	
}
