/**
* VisitMemberEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { VisitEntity } from "./VisitEntity";
import { PersonEntity } from "./PersonEntity";


declare global {
    interface EntityTypeMap {
        "VisitMember": VisitMemberEntity
    }
}

export abstract class VisitMemberEntity extends BaseEntity {

	 abstract visitMemberId: string;
	 abstract visit: VisitEntity;
	 abstract templateId?: string;
	 abstract presenceType?: number;
	 abstract isConvoked?: boolean;
	 abstract type?: string;
	 abstract order?: number;
	 abstract convokedOn?: Date;
	 abstract penalty?: number;
	 abstract absenceResetCount?: number;
	 abstract lateResetCount?: number;
	 abstract excusedResetCount?: number;
	 abstract presenceResetCount?: number;
	 abstract person?: PersonEntity;
	
}
