/**
* RegionTypeEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { RegionEntity } from "./RegionEntity";


declare global {
    interface EntityTypeMap {
        "RegionType": RegionTypeEntity
    }
}

export abstract class RegionTypeEntity extends BaseEntity {

	 abstract regionTypeId: string;
	 abstract name: string;
	 abstract nameSystem: string;
	 abstract isDefault: boolean;
	 abstract nameComputed: string;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract templateName?: string;
	 abstract order?: number;
	 abstract regions?: ReadonlyArray<RegionEntity>;
	
}
