/**
* VisitTypeEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 871
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { PhaseEntity } from "./PhaseEntity";
import { BugGroupEntity } from "./BugGroupEntity";
import { BugStateEntity } from "./BugStateEntity";
import { VisitEntity } from "./VisitEntity";


declare global {
    interface EntityTypeMap {
        "VisitType": VisitTypeEntity
    }
}

export abstract class VisitTypeEntity extends BaseEntity {

	 abstract visitTypeId: string;
	 abstract isDefault: boolean;
	 abstract eventPriority: number;
	 abstract project: ProjectEntity;
	 abstract templateName?: string;
	 abstract templateId?: string;
	 abstract name?: string;
	 abstract visitAspects?: string;
	 abstract phase?: PhaseEntity;
	 abstract bugGroup?: BugGroupEntity;
	 abstract openBugState?: BugStateEntity;
	 abstract visits?: ReadonlyArray<VisitEntity>;
	
}
